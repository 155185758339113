import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TabTypes } from "../../helpers/constants";
import { tabBarHeight, trayHeight } from "./Dashboard";
import clsx from "clsx";
import { useAppSelector } from "../app/appHooks";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { minToolbarHeight } from "../app/App";
import { NotificationCard } from "./NotificationCard";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      position: "relative",
      zIndex: 2,
      alignContent: "flex-start",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      overflowY: "auto",
      padding: theme.spacing(2, 2),
    },
    openTrayHeight: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} - ${trayHeight})`,
      paddingBottom: tabBarHeight,
    },
    minimizedTrayHeight: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} )`,
      paddingBottom: tabBarHeight,
    },
    sectionHeader: {
      width: "100%",
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      marginTop: theme.spacing(1),
    },
    communitiesContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    timeframeSelect: {
      minWidth: 120,
    },
    selectOption: {
      padding: theme.spacing(1),
    },
    title: {
      fontSize: "1.5rem",
    },
  })
);

export default function AllNotifications(props: IProps) {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  const { addTab, openTray, minimizedTray } = props;

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.container,
        openTray && classes.openTrayHeight,
        minimizedTray && classes.minimizedTrayHeight
      )}
      id="all-notifications-container"
    >
      <div className={classes.sectionHeader}>
        <Typography className={classes.title}>
          {t("all_notifications")}
        </Typography>
      </div>
      <div className={classes.communitiesContainer}>
        <NotificationCard addTab={addTab} />
      </div>
    </div>
  );
}

interface IProps {
  openTray: boolean;
  minimizedTray: boolean;
  addTab: (id: string, type: TabTypes) => void;
}
