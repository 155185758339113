import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch } from "../app/appHooks";
import { useAppSelector } from "../app/appHooks";
import { Typography } from "@material-ui/core";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { setCommunityEvents } from "./dashboardSlice";
import { getEventsToUpdateFirebase } from "../../helpers/utils";
import { TabTypes } from "../../helpers/constants";
import { NotificationLayout } from "./NotificationLayout";
import logo from "../../assets/img/stackcare_logo.transparent.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    communityCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.light.light,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      borderRadius: "5px",
    },
    cardTitle: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
    },
    disabledCard: {
      cursor: "default",
      pointerEvents: "none",
    },
    disabledButton: {
      color: theme.palette.action.disabled,
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    unitContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 220px)",
      gap: "15px",
      width: "100%",
      marginTop: "20px",
      justifyContent: "center",
    },
    logoContainer: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "220px",
    },
    logoImg: {
      maxWidth: "100%",
      height: "300px",
      opacity: 0.5,
    },
    emptyText: {
      opacity: 0.5,
    },
    notificationWrapper: {
      width: "220px",
      height: "140px",
    },
  })
);

export const NotificationCard = (props: IProps) => {
  const { addTab } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /* ---- Selector ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const communities = useAppSelector(
    (states) => states.headerState.communities
  );
  const allUnits = useAppSelector((state) => state.dashboardState.allUnits);
  const communityEvents = useAppSelector(
    (state) => state.dashboardState.communityEvents
  );
  const user = useAppSelector((state) => state.headerState.user);

  /* ---- Effects ---- */
  useEffect(() => {
    if (firebaseAuth && communities && user && user.roles) {
      let allCommunityEvents: any[] = [];
      // Fetch events for each community in the list
      const communityIds = new Set();
      const unitIds = new Set();
      user.roles.forEach((role) => {
        if (role.role.all_units) {
          communityIds.add(role.role.community_id);
        } else {
          if (role.units) {
            role.units.forEach((unit) => {
              unitIds.add(unit.id);
            });
          }
        }
      });

      const promises = communities.map(({ id: communityID }) =>
        getFirebaseDBReference()
          .ref(`communities/${communityID}/unresolved_events`)
          .orderByChild("time_created")
          .once("value")
          .then((snapshot) => {
            const vals = snapshot.val();
            let _records = getEventsToUpdateFirebase(vals);
            _records = _records.filter((event) => {
              const eventUnitID = event.unit_id;
              const eventCommunityID = event.community_id;

              // Check if the user has access to the unit or community
              return (
                communityIds.has(eventCommunityID) || unitIds.has(eventUnitID)
              );
            });
            allCommunityEvents = [...allCommunityEvents, ..._records];
          })
      );
      // Wait for all Firebase requests to finish
      Promise.all(promises).then(() => {
        dispatch(setCommunityEvents(allCommunityEvents));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communities, firebaseAuth]);

  return (
    <div>
      <div className={classes.unitContainer}>
        {communityEvents !== undefined &&
          communityEvents.length > 0 &&
          communityEvents.map((event) => {
            const unit = allUnits[event.unit_id];
            if (!unit || !unit.residents) return null;
            return (
              <div key={event.id} className={classes.notificationWrapper}>
                <NotificationLayout
                  event={event}
                  unit={unit}
                  onClickOpenTab={addTab}
                />
              </div>
            );
          })}
      </div>
      {(communityEvents === undefined || communityEvents.length === 0) && (
        <div className={classes.logoContainer}>
          <img
            src={logo}
            alt="StackCare Logo"
            className={classes.logoImg}
            draggable={false}
          />
          <Typography className={classes.emptyText}>
            {t("no_active_notifications")}
          </Typography>
        </div>
      )}
    </div>
  );
};

interface IProps {
  addTab: (id: string, type: TabTypes) => void;
}
